<template>
  <section>
    <div>
      <div class="w-full flex">
        <div class="w-4/12">
          <AutoComplete v-model="contratosStore._articulos.lista_precio_id" size="small" optionLabel="Nombre" dropdown :force-selection="true" inputClass="w-full text-sm" panelClass="text-sm" class="w-9/12 h-10" :suggestions="listaPrecioStore._listas_precio_buscadas" @complete="buscarListas($event)" />
          <div class="w-full">            <span class="text-xs italic  text-gray-600 w-full">
              Si no encuentras el listado de precios adecuado
              <router-link target="_blank" :to="{name: 'pharmasan.ventas.lista-precio', target: '_blank', query: { crear: true }}"
                class="text-blue-600 font-bold italic"
              >
                +Crea uno
              </router-link>
            </span>
          </div>
        </div>
        <div class="flex w-8/12 justify-end gap-2">
          <div class="w-4/12 h-11" v-if="documento">
            <div class="bg-blue-300 flex justify-between rounded-md h-full p-2 text-blue-600 font-bold text-sm border border-r-2">
              <div class="flex gap-2">
                <p>{{ documento ?  documento.name : 'articulos.xlsx' }}</p>
              </div>
              <button @click="eliminarArchivo">
                <i class="pi pi-times text-sm"></i>
              </button>
            </div>
          </div>
          <FileUpload
            v-else
            mode="basic"
            class="h-10 w-full bg-white text-blue-600"
            name="demo[]"
            url="./upload.php"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            :maxFileSize="1000000"
            chooseLabel="Actualizar articulos masivo"
            @change="subirArticulos()"
            ref="file"
          ></FileUpload>
          <Button v-if="documento" class="h-10 w-4/12" severity="success" label="Guardar" @click="actualizarArticulosMasivo" icon="pi pi-save" />
          <Button @click="contratosStore.descargarPlantillaMasivoArticulos()" v-if="!documento" class="h-10 w-4/12" label="Descargar plantilla articulos" icon="pi pi-download" severity="secondary" />
          <Button @click="displayModalCrearArticulo = true" label="Crear articulo" v-if="!documento" class="h-10 w-4/12" icon="pi pi-plus" />
        </div>
      </div>
      <!-- <div v-if="contratosStore._medicamentos_ingresados" class="p-2 rounded-md bg-gray-300 text-blue-600  mt-4 italic text-sm">
        <p>Se cargaron <span class="font-bold">{{ contratosStore._medicamentos_ingresados.length }}</span> artículos</p>
      </div> -->
      <div class="w-full mt-4 py-2">
        <div class="w-full">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filtros.ItemCode.value" @input="getArticulos" placeholder="Buscar articulo" />
          </span>
        </div>
        <DataTable
          :value="contratosStore._paginado_articulos.rows"
          class="text-xs p-datatable-sm mt-2"
          showGridlines
        >
          <Column field="ItemCode" header="Medicamentos"></Column>
          <Column field="ItemName" header="Nombre"></Column>
          <Column field="UserText" header="Descripción"></Column>
          <!-- <Column field="U_PHR_Concentracion" header="Concentración"></Column> -->
          <Column field="U_PHR_PrincActivo" header="Principio activo"></Column>
          <Column field="U_PHR_PrincActivo" header="Estado">
            <template #body="slotProps">
              <InputSwitch @change="cambiarEstadoArticulo(slotProps.data)" v-model="slotProps.data.Status" />
            </template>
          </Column>
          <!-- <Column field="U_PHR_PrincActivo" header="Estado"></Column> -->
        </DataTable>
        <Paginator :rows="limit" @page="onPage($event)" :totalRecords="contratosStore._paginado_articulos.count" :rowsPerPageOptions="[10, 20, 30, 100, 500]"></Paginator>
      </div>
    </div>
    <Dialog modal v-model:visible="displayModalCrearArticulo" header="Crear articulo" :style="{ width: '30rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <div class="w-full">
        <label class="w-full text-xs text-gray-600 italic" for="itemCode">Codigo del articulo</label>
        <InputText class="w-full mb-2" id="itemCode" type="text" v-model="dataCrearArticulo.ItemCode" />
        <label class="w-full text-xs text-gray-600 italic" for="tipo">Tipo</label>
        <Dropdown id="tipo" v-model="dataCrearArticulo.tipo" :options="tiposArticulo" class="w-full" />
        <div class="mt-4 flex justify-end gap-2">
          <Button @click="cerrarModal" severity="secondary" label="Cancelar" />
          <Button @click="crearArticulo" label="Guardar" />
        </div>
      </div>
    </Dialog>
  </section>
</template>
<script>
  import { useContratosStore } from '../../../stores/contratos.store'
  import { useListaPrecioStore } from '../../../stores/lista-precio.store'
  import { ref, onMounted, computed } from 'vue'
  import { FilterMatchMode } from 'primevue/api'
  import { useToast } from 'primevue/usetoast'
  import { useRoute } from 'vue-router'
  import ServiceContrato from '../../../services/contrato-precio.service'
  import { helper } from '@/utils/helper'
  // import XLSX from 'xlsx'
  // import dayjs from 'dayjs'
  import Swal from 'sweetalert2'
  export default {
    name: 'crearContratoPacientes',
    setup () {
      const toast = useToast()
      const route = useRoute()
      const offset = ref(0)
      const limit = ref(10)
      const documento = ref()
      const displayModalCrearArticulo = ref(false)
      const params = computed(() => {
        return {
          offset: offset.value,
          limit: limit.value
        }
      })
      const dataCrearArticulo = ref({
        ItemCode: '',
        tipo: '',
        ListPrice: 0
      })
      const tiposArticulo = ref(['CRONICO', 'AGUDO'])
      const contratosStore = useContratosStore()
      const listaPrecioStore = useListaPrecioStore()
      const _serviceContrato = new ServiceContrato()
      const listaSeleccionada = ref()
      const file = ref()
      const filtros = ref({
          ItemCode: { value: null, matchMode: FilterMatchMode.CONTAINS }
      })
      const subirArticulos = () => {
        const archivo = file.value.files[0]
        documento.value = archivo
        if (archivo) {
          const reader = new FileReader()
          reader.onload = async (event) => {
            contratosStore.medicamentos_ingresados = { base64: await helper.base64String(archivo), ListPrice: contratosStore._articulos.lista_precio_id.ListPriceCL }
            const validArt = await _serviceContrato.validArticulos({ base64: await helper.base64String(archivo) }, contratosStore._articulos.lista_precio_id.ListPriceCL)
            if (validArt.data) {
              Swal.fire({
                icon: 'warning',
                title: 'Validacion Articulos',
                text: validArt.data
              })
            }
          }
          reader.readAsArrayBuffer(archivo)
        }
      }
      const buscarListas = ({ query }) => {
        listaPrecioStore.buscarListaPrecio(query || 'a')
      }
      const eliminarArchivo = () => {
        contratosStore.medicamentos_ingresados = []
        contratosStore.articulos.articulos = []
        documento.value = null
      }
      const getArticulos = () => {
        const busqueda = {}
        for (const [key, value] of Object.entries(filtros.value)) {
          if (value.value) {
            busqueda[key] = value.value
          }
        }
        const parametros = {
          ...params.value,
          ...busqueda
        }
        contratosStore.paginarArticulos(route.params.id, parametros)
      }
      const onPage = ({ first }) => {
        offset.value = first
        getArticulos()
      }
      const crearArticulo = () => {
        if (!contratosStore._articulos.lista_precio_id?.ListPriceCL) return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes seleccionar una lista de precio para coontinuar', life: 5000 })
        const payload = {
          ItemCode: dataCrearArticulo.value.ItemCode.trim(),
          ListPrice: contratosStore._articulos.lista_precio_id.ListPriceCL,
          tipo: dataCrearArticulo.value.tipo
        }
        contratosStore.crearArticulosContrato(route.params.id, { ListPrice: contratosStore._articulos.lista_precio_id.ListPriceCL, data: [payload] }).then((data) => {
          if (data) {
            Swal.fire({
              title: 'Guardado',
              text: 'Articulo agregado',
              icon: 'success'
            }).then(() => {
              getArticulos()
            })
            cerrarModal()
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error al ingresar el articulo'
            })
          }
        })
      }
      const cerrarModal = () => {
        displayModalCrearArticulo.value = false
        dataCrearArticulo.value = {
          ItemCode: '',
          tipo: ''
        }
      }
      const cambiarEstadoArticulo = (articulo) => {
        contratosStore.cambiarEstadoArticuloContrato(route.params.id, articulo.Id, { Status: articulo.Status }).then(() => {
          toast.add({ severity: 'info', summary: 'Estado cambiado con éxito', detail: `Se cambo el estado del medicamento ${articulo.ItemCode} a ${articulo.Status ? 'Activo' : 'Inactivo'}`, life: 5000 })
          getArticulos()
        })
      }
      const actualizarArticulosMasivo = () => {
        contratosStore.crearArticulosContrato(route.params.id, contratosStore.medicamentos_ingresados).then((data) => {
          if (data) {
            Swal.fire({
              icon: 'success',
              title: 'Guardado',
              text: 'Articulos actualizados con éxito'
            }).then(() => {
              contratosStore.medicamentos_ingresados = {}
              documento.value = null
              getArticulos()
            })
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un erro al cargar los articulos, intenta de nuevo mas tarde'
            })
          }
        })
      }
      onMounted(() => {
        listaPrecioStore.listarListasPrecio()
        getArticulos()
      })
      return {
        contratosStore,
        listaPrecioStore,
        subirArticulos,
        file,
        buscarListas,
        listaSeleccionada,
        filtros,
        eliminarArchivo,
        limit,
        offset,
        onPage,
        getArticulos,
        displayModalCrearArticulo,
        dataCrearArticulo,
        tiposArticulo,
        cerrarModal,
        crearArticulo,
        cambiarEstadoArticulo,
        documento,
        actualizarArticulosMasivo
      }
    }
  }
</script>
